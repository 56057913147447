import { useField } from "formik";
import { ActionFeedback } from "lib/action-feedback";
import React from "react";
import styles from "./omnijus-file-field.module.scss";
import fieldStyles from "../../field.module.scss";
import classNames from "classnames";
import { PublishIcon } from "components/icons/publish-icon/publish-icon";

export interface DataUrlFile {
    name: string;
    dataUrl: string;
}

export interface OmnijusFileFieldProps {
    name: string;
    label?: string;
    className?: string;
    accept?: string;
    asDataUrl?: boolean;
    maxSizeMB?: number;
    fileNameComponent?: (name: string) => React.ReactNode;
}

export const OmnijusFileField = (props: OmnijusFileFieldProps) => {
    const [field, meta, helpers] = useField({ name: props.name });
    const file = field.value;
    return (
        <div className={classNames([fieldStyles["field-control"], props.className])}>
            {props.label ? (
                <label className={fieldStyles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            {(props.asDataUrl && file?.dataUrl?.length > 0) || (!props.asDataUrl && file) ? (
                <div className={styles.fileName}>
                    {props.fileNameComponent ? props.fileNameComponent(file?.name) : file?.name}
                    <button
                        className={styles.clearButton}
                        onClick={() => {
                            helpers.setValue(undefined);
                        }}
                    >
                        x
                    </button>
                </div>
            ) : (
                <label className={styles.loadFileLabel}>
                    Escolher arquivo
                    <PublishIcon className={styles.loadFileIcon} title="Carregar arquivo" />
                    <input
                        type="file"
                        name={field.name}
                        onBlur={field.onBlur}
                        value={undefined}
                        className={styles.invisible}
                        accept={props.accept}
                        onChange={(e) => {
                            const files = e.target.files;
                            if (files && files.length > 0) {
                                if (props.maxSizeMB) {
                                    const size = files[0].size / 1024 / 1024;
                                    if (size > props.maxSizeMB) {
                                        ActionFeedback.error({
                                            title: `O tamanho do arquivo excede ${props.maxSizeMB}MB`,
                                        });
                                        return;
                                    }
                                }
                                if (props.asDataUrl) {
                                    const fr = new FileReader();
                                    fr.onload = () => {
                                        helpers.setValue({ name: files[0].name, dataUrl: fr.result });
                                    };
                                    fr.readAsDataURL(files[0]);
                                } else {
                                    helpers.setValue(files[0]);
                                }
                            }
                        }}
                    />
                </label>
            )}
            <div className={fieldStyles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
        </div>
    );
};
