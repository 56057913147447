import React, { useContext } from "react";
import { Loading } from "../../loading/loading";
import { ValoresComboContext } from "../valores-combo-context";
import styles from "./filtros-aplicados.module.scss";

export interface FiltroAplicado {
    label?: string;
    name: string;
    value?: string;
    loading: boolean;
    onRemove?: (label: string) => void;
}

export const FiltrosAplicados = ({
    parametros,
    onRemove,
}: {
    parametros: object;
    onRemove?: (nome: string) => void;
}) => {
    const context = useContext(ValoresComboContext).valoresCombos;
    const filtros: FiltroAplicado[] = Object.entries(parametros).map(([key, value]) => {
        const infoParametro = context[key];
        const filtro: FiltroAplicado = { name: key, loading: false, onRemove };
        if (infoParametro) {
            filtro.label = infoParametro.label;
            if (infoParametro.idValue && infoParametro.idValue[value]) {
                filtro.value = infoParametro.idValue[value];
            } else if (infoParametro.format) {
                filtro.value = infoParametro.format(value);
            }
        } else {
            filtro.value = String(value);
        }
        return filtro;
    });

    return (
        <div className={styles.filtrosAplicados}>
            <span className={styles.prefix}>Filtros aplicados:</span>
            {filtros.map((t) =>
                t.value || t.loading ? (
                    <div key={t.name} className={styles.tag}>
                        <span>{t.label || t.name}</span>
                        <span>:</span>
                        <span className={styles.value}>
                            {t.loading ? (
                                <Loading inline explicitReload={true}>
                                    {() => <></>}
                                </Loading>
                            ) : (
                                t.value
                            )}
                        </span>
                        {t.onRemove && (
                            <span className={styles.botaoFechar} onClick={() => t.onRemove && t.onRemove(t.name)}>
                                x
                            </span>
                        )}
                    </div>
                ) : (
                    null
                )
            )}
        </div>
    );
};
